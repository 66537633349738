import React from "react";
import "./index.css";
import data from "../../Assets/Data";

const Investmanagement = () => {
  return (
    <div>
      <div className="container-fluid investmanagement">
        <div className="container ">
          <div className="investtitle">
            <h6>AGILE INVESTMENT MANAGEMENT</h6>
            <h2 data-text="Steps">
              The choice of the sector is driven by passion.
            </h2>
          </div>
          <div class="text-box ">
            <p >
              <strong> Through our investments, we want to</strong>
            </p>
            <ul >
              <li aria-level="1">
                <span >
                  Reduce skills required in multi-cloud operations.
                </span>
              </li>
              <li  aria-level="1">
                <span >
                  Resolve skills and knowledge scarcity from the cloud
                  eco-system.
                </span>
              </li>
              <li  aria-level="1">
                <span >
                  Easy accessibility of Boutique products from India to the
                  world through our E-commerce initiatives.
                </span>
              </li>
              <li  aria-level="1">
                <span >
                  Initiative to heal the world with greenery. Planting of 20,000
                  trees is in progress.
                </span>
              </li>
              <li  aria-level="1">
                <span >
                  Invest in rural India – we aspire to bring structure to the
                  unorganized agricultural and dairy sector. We took the
                  challenge of bring standardization to the unorganized sector
                  and started investing towards it.
                </span>
              </li>
            </ul>
          </div>
          <div className="row">
            {data.processbox.map((val, id) => {
              return ( 
                <div className=" col-sm-3  col-6">
                  <div className="">
                  
                  <div className="process-box">
                  <span>{val.no}</span>
                <div className="processboxfig">
                    <div className="imgbox"><img  className="procesicons" src={val.img}/></div>
                    <h5>{val.name}</h5>
                </div>
              
                  </div>
                  </div>
                </div>

              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investmanagement;
