import React from "react";
import "./index.css";
import videobg from "../../Assets/videos/banner-bg.mp4";

import Conquerinvest from "../../component/Conquerinvest";
import Investsectors from "../../component/investmentsectors";
import Teammember from "../../component/team-members";
import Knowmore from "../../component/know-more";
import Ourportfolio from "../../component/ourportfolio";
import Investmanagement from "../../component/agileinvestmng";
import Allcanfarm from "../allcanfarm";
import Preloader from "../../component/Preloader";

const Aboutus = () => {
  return (
    <>
      {/* <Preloader/> */}
      <div>
        <div className="container-fluid p-0">
          <div className="pageheader">
            <aside className="leftsidetag">
              <ul className="liststylebg">
                <li>
                  <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                    FACEBOOK
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="LINKEDIN">
                    LINKEDIN
                  </a>
                </li>
              </ul>
            </aside>
            <div className="scrolldwn1">
              <small className="scroll-down-small">SCROLL DOWN</small>
              <span></span>
            </div>
            <div className=" video-bg">
              <video
                autoPlay
                playsInline
                loop
                muted
                className="videobgname"
                style={{ pointerEvents: "none" }}
              >
                <source src={videobg} />
              </video>
              <div className="container  ">
                <h1 className="headingline2">ABOUT US</h1>
                <p>AVIGNA WILL GUIDE YOU TO ACHIEVE YOUR DREAMS TO GROW</p>
              </div>
            </div>
          </div>
        </div>

        <Conquerinvest
          heading="CONQUER INVEST ACHIEVE"
          subhaeding="Avigna believes in investing in IT solutions to improve the livelihood."
        />
        <Investsectors />
        <Teammember />
        <Investmanagement />
        <Knowmore />
        <Ourportfolio />
      </div>
    </>
  );
};

export default Aboutus;
