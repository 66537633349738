import React, { useEffect } from "react";
import Router from "./Routers";
import Preloader from "./component/Preloader";

;

function App() {
  return (
    
    <div className="App">
    
     <Router/>
    </div>
  
  );
}

export default App;
