import React from "react";
import videobg from "../../Assets/videos/banner-bg.mp4";
import purpletulibanner from "../../Assets/images/Purple-TulsiBig-banner.jpg";
import purpletulsi1stimg from "../../Assets/images/Puprle-Tulsi1stimg.jpg"
import purpletulsi2ndimg from "../../Assets/images/Puprle-Tulsi2ndimg.jpg";
import Preloader from "../../component/Preloader";

const Purpletulsi = () => {
  return (
    <>
    {/* <Preloader/> */}
    <div>
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">Purple Tulsi</h1>
              <p>YOUR RETAIL SEARCH ENDS HERE</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container  ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA – REVOLUTION IN RETAIL</h6>
                <h2>
                  <strong>Purple Tulsi</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>YOUR ONE STOP SHOPPING DESTINATION.</h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  It is state-of-the-art online retail store, providing services
                  in Singapore. It includes latest fashion garments for men,
                  women, kids, trendy jewellery, pooja items, home &amp; living.
                  Avigna has come up with this e-commerce site to bridge the gap
                  between the retailers and the consumers.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p style={{textAlign: "left"}}>
                        Online retail store for Indian community in Singapore.
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2016</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
              <div className="innercol">
                <div className="row m-0 ">
                  <div className="col-sm-12 p-0 ">
                    <p className="px-0">
                      <img
                        className="milkbannerimg"
                        src={purpletulibanner}
                      ></img>
                    </p>

                    <div className="row mx-0">
                      <div className="col-sm-6 colmilk ps-0">
                        <p className="px-0">
                          <img
                            className="milkvillaimges"
                            src={purpletulsi1stimg}
                          />
                        </p>
                      </div>

                      <div className="col-sm-6  colmilk pe-0">
                        <p className="">
                          <img
                            className="milkvillaimges"
                            src={purpletulsi2ndimg}
                          />
                        </p>
                      </div>
                    </div>

                    <p className=" milkdes p-0">
                      <strong>Purple Tulsi</strong> has been providing retail
                      goods ranging from latest fashion garments for men, women,
                      kids, trendy jewellery, pooja items, home &amp; living to
                      the Indian community based in Singapore. All available
                      products are of high quality and procured from Indian
                      markets. Indians in Singapore Avigna is building the
                      bridge between the retailers in India and customers in
                      Singapore.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Purpletulsi;
