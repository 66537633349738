import React from "react";
import videobg from "../../Assets/videos/banner-bg.mp4";
import "./index.css";
import milkvillabanner from "../../Assets/images/MilkVilla-Main-Banner.jpg";
import milkimg1 from "../../Assets/images/MilkVillistimg.jpg";

import milkvilla2ndimg from "../../Assets/images/MilkVilla2ndimg.jpg";
import Preloader from "../../component/Preloader";
const Milkvilla = () => {
  return (
    <>
{/* <Preloader/> */}
    <div>
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">Milk Villa</h1>
              <p>DIGITIZED MILK SUPPLY</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container  ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA – REVOLUTIONIZING MILK SUPPLY CHAIN</h6>
                <h2>
                  <strong>Milkvilla</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>
                  TO DELIVER FRESHER, HEALTHIER MILK TO MASSES
                </h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  <strong>Milkvilla</strong> is a hyperlocal supply chain
                  network with direct access to primary producers and consumers
                  without the involvement of any artificial processing of milk.
                  Avigna is playing an important role by investing in and
                  supporting this supply chain of uninterrupted milk supply
                  across the urban landscape from the dairy-rich villages across
                  India. This portfolio is under the flagship of AllCanFarm and
                  is focused on the amalgamation of technology in milk supply,
                  which is still not a common path opted for by many dairy
                  firms.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p style={{textAlign: "left"}}>Technology driven milk supply chain</p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
            <div className="innercol">
              <div className="row m-0 ">
                <div className="col-sm-12   p-0 ">
               
                    <p className="px-0">
                      <img
                        className="milkbannerimg"
                        src={milkvillabanner}
                      ></img>
                    </p>
                  
                
              
              <div className="row mx-0">
                <div className="col-sm-6  colmilk ps-0">
                
                    <p className="px-0">
                    <img className="milkvillaimges" src={milkimg1} /></p>
                
                </div>
               
                <div className="col-sm-6 colmilk pe-0">
                 
                    <p className="">
                    <img className="milkvillaimges" src={milkvilla2ndimg} /></p>
                  
                </div>
              </div>
        
              <p className=" milkdes p-0">
                <strong>Milkvilla</strong> is a game-changing hyperlocal supply
                chain network that has reimagined the traditional milk
                distribution system. Operating as a decentralized network of
                farmers and consumers, Milkvilla enables direct access to
                primary producers and doorstep milk collection and delivery in
                non-packaged form. Leveraging cutting-edge technology, the
                entire milk supply chain has been digitalized, leading to
                remarkable reductions in leakage and processing inefficiencies.
                Milkvilla has quickly become a pioneer in transforming the way
                milk is sourced and distributed. Milkvilla is on a mission to
                bring fresher, healthier milk to consumers while empowering
                farmers and promoting sustainability in the dairy industry.
              </p>
              </div>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Milkvilla;
