import React from "react";
import videobg from "../../Assets/videos/banner-bg.mp4";
import jankibanner from "../../Assets/images/Janki-banner.jpg";
import janki1stimg from "../../Assets/images/Janki1stimg.jpg";
import janki2ndimg from "../../Assets/images/Janki2ndimg.jpg";
import Preloader from "../../component/Preloader";

const JankiAgro = () => {
  return (
    
    <>
    {/* <Preloader/> */}
    <div>
      
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">Janki Agro</h1>
              <p>SOCIAL ENTERPRISE FOR DAIRY FARMERS</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container  ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA – PATH TOWARDS ORGANIZED DAIRY FARMING</h6>
                <h2>
                  <strong>Janki Agro</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>
                  Building a social enterprise of collecting milk from villages
                  and small farmers.
                </h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  Janki Agro focuses on bringing structure to unorganized dairy
                  industry. It’s a social welfare enterprise where milk is
                  collected from dairy farmers and kept in refrigerated storage
                  plants for conducting lab test while maintaining its pure and
                  natural state. Then milk is transported to the Dairy plants.
                  300+ dairy farmers are associated with this initiative, where
                  cows and buffaloes are adopted for dairy farming. Per day 5000
                  litres milk is processed at the dairy plants under this
                  initiative.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p style={{textAlign: "left"}}>
                        Initiative to bring structure to unorganized dairy
                        sector of Bihar, India.
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2021</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
              <div className="innercol">
                <div className="row m-0 ">
                  <div className="col-sm-12 p-0 ">
                    <p className="px-0">
                      <img className="milkbannerimg" src={jankibanner}></img>
                    </p>

                    <div className="row mx-0">
                      <div className="col-sm-6  colmilk ps-0">
                        <p className="px-0">
                          <img className="milkvillaimges" src={janki1stimg} />
                        </p>
                      </div>

                      <div className="col-sm-6  colmilk pe-0">
                        <p className="">
                          <img className="milkvillaimges" src={janki2ndimg} />
                        </p>
                      </div>
                    </div>

                    <p className=" milkdes p-0">
                      With this platform, we are aiming at proving world class
                      expertise and scientific knowledge to support development
                      and promotion of unorganized dairy industry. Farmers will
                      be updated with the information on new research and new
                      advancement in dairy farming. Training and motivation
                      along with guidance will be provided to the farmers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default JankiAgro;
