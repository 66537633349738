import React from "react";
import "./index.css"

import { Outlet, Link } from "react-router-dom";
import videobg from "../../Assets/videos/banner-bg.mp4";
import Navbar from "../Navbar";

const Navmenu = (props) => {
  return (
    <div>
    
      <div 
      // className=" navigationmenu  container-fluid"
      className={props.show ? 'navigationmenu  container-fluid active':"navigationmenu  container-fluid"}
      >   
      <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname2"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
        <div className="bg-layers ">
     
       
            
            <span ></span>
            <span ></span>
            <span ></span>
            <span ></span>
            {/* end bg layers */}

          
        </div>
        <div className="  innermenu">
            <div className="menuinside">
                <ul className="menuul">
                <li class="menu-item"><Link  to="/" onClick={()=>{props.setShow(!props.show ,)}}>HOME</Link></li>
                <li class="menu-item"><Link to="/about-us"   onClick={()=>{props.setShow(!props.show)}}>ABOUT US</Link></li>
                <li class="menu-item"><Link to="/portfolio"  onClick={()=>{props.setShow(!props.show)}}>PORTFOLIO</Link></li>
                <li class="menu-item"><Link to="/contact-us"   onClick={()=>{props.setShow(!props.show)}}>CONTACT US</Link></li>
                </ul>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Navmenu;
