import React, { useState } from "react";
import Avignaimg from "../../Assets/images/Avigna-logo.svg";
import "./index.css";
import Navmenu from "../navigation-menu";
import {RxCross1} from "react-icons/rx";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  const [show, setShow] = useState(false);
  



  return (
    <div>
   
 
      <div className=" container-fluid ">
        <div className="row  d-flex align-items-center  navbartop">
          <div className="col-4 p-0">
            <div className="text-start ">
              
              <a
                className="direction"
                href="https://goo.gl/maps/2jQ2f4Mu5knYvY1Z7"
                target="_blank"
                title="FACEBOOK"
              >
                DIRECTION
              </a>
            </div>
          </div>
          <div className="col-4 p-0">
            <div className="Avtarbox">
            <Link to="/">    <img className="avatar" src={Avignaimg} /></Link>
              
            </div>
          </div>
          <div className="col-4 p-0">
            <div className=" rightwrap text-end   ">
              <div className="hamburger-menu">
              <a onClick={() => props.setShow(!props.show)}   className="menu">MENU</a>
           
            <div onClick={() => props.setShow(!props.show)} className="hamburger" id="hamburger-menu">
            
            {!props.show?
            <>
              <span className="istline"></span>
              <span className="2ndline"></span>
              <span className="3rdline"></span>
            </> 
             : <RxCross1  className="crossicon" id="hamburgeropn"/>
            }
            </div>
           
            </div>
  
            </div>
          </div>
        </div>
        
      </div>
      {/* {show ?
   <Navmenu show={show} setShow={setShow}/> 
              : null} */}
  
    </div>
  );
};

export default Navbar;
