import React from 'react';
import "./index.css";
import videobg from "../../Assets/videos/banner-bg.mp4";
import Infraguard from '../infraguard';
import Ourinvestport1 from '../../component/ourinvestportfolio';
import Preloader from '../../component/Preloader';



const Portfolio = () => {
  
  return (
    <>
    {/* <Preloader/> */}
    <div>
           <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/avigna/" target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
             
              <h1 className="headingline2">
                PORTFOLIO
               
            
              </h1>
              <p>AVIGNA HELPS TO SAIL THROUGH THE IFS OF FINANCIALS. INVESTMENTS MATTERS, A STRONG PILLAR</p>
            
            </div>
          </div>
        </div>
      </div>
<Ourinvestport1/>
      
    </div>
    </>
  );
};

export default Portfolio;