import React from "react";
import "./index.css";
import data from "../../Assets/Data";
const Ourvalues = () => {
  return (
    <div>
      <div className="container-fluid ourvaluescon noise" >
        <div className="container">
            <div className="valtitle .light"> 
          <h6 className="ourvalueshead">OUR CORE VALUES</h6>
          <h2>Core values allows us to make right decisions.</h2></div>
          <div className="row">
            {data.ourvalues.map((val, id) => {
              return (
                <div className="col-sm-4">
                    <div className=" servicesbox d-flex">
                    <div className="leftdiv">
                        <small>{val.valno}</small>
                        <span></span>

                    </div>
                   

                <div className="rightdiv">  <h4><b>{val.name}</b></h4></div></div>
                  <div>
                  <p className="ourdes"><span>{val.des}</span></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourvalues;
