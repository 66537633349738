import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "../view/homepage";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

import Portfolio from "../view/Portfolio";
import Contactus from "../view/contactus";

import Aboutus from "../view/About-us";
import Allcanfarm from "../view/allcanfarm";
import Milkvilla from "../view/milkvilla";
import Infraguard from "../view/infraguard";
import Kumolous from "../view/kumolous";
import Purpletulsi from "../view/purpletulsi";
import JankiAgro from "../view/jankiagro";
import Dhurmaati from "../view/dhurmaati";
import { useState } from "react";
import Navmenu from "../component/navigation-menu";
import Preloader from "../component/Preloader";
import Thankew from "../component/Thankewpage";


// import

const Router = () => {
  const [show, setShow] = useState(false);
  return (
    
    <BrowserRouter>
        
    
      <Navbar show={show} setShow={setShow} />
      {show ? (
        <Navmenu show={show} setShow={setShow} />
      ) : (
        
        <div>
          
          
          <Routes>
            
            
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/about-us" element={<Aboutus />} />

            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/contact-us" element={<Contactus />} />
            <Route
              exact
              path="/portfolio/allcan-farm"
              element={<Allcanfarm />}
            />
            <Route exact path="/portfolio/milk-villa" element={<Milkvilla />} />
            <Route exact path="/portfolio/kumolous" element={<Kumolous />} />
            <Route
              exact
              path="/portfolio/purple-tulsi"
              element={<Purpletulsi />}
            />
            <Route exact path="/portfolio/janki-agro" element={<JankiAgro />} />
            <Route exact path="/portfolio/dhur-maati" element={<Dhurmaati />} />
            <Route
              exact
              path="/portfolio/infraguard"
              element={<Infraguard />}
            />
                <Route
              exact
              path="/thank-you"
              element={<Thankew/>}
            />
             {/* <Route
              exact
              path="/Thank-you"
              element={<Infraguard />}
            /> */}
          </Routes>
          <Footer />
        </div>
      )}
      {/* {show ?
    } */}
    </BrowserRouter>
    
  );
};

export default Router;
