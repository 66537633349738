import React from "react";
import "./index.css";
import Investappimg from "../../Assets/images/Investmentappimg.jpg";

const InvestmentApproach = (props) => {
  return (
    <div>
      <div>
        <div className="Container-fluid investmentcon">
          <div className="container   ">
            <div className="row">
              <div className="col-sm-12 p-0">
          
        
              
            
        
            
              <div className="innercol">
            <div className="invlesappttittal">
              <h6 className="investheading">{props.heading}</h6>
              <h2 data-text="Global" className="investappsubheading">{props.subhaeding}</h2>
            </div>
            <div className="row p-0">
              <div className="col-md-5 p-0">
                <div className="innercol">
                  <div className="imgbox1">
                  <img className="investappimg" src={Investappimg}>{props.img}</img></div> 
                </div>
              </div>
              <div className="col-md-7  p-0">
                <div className="innercol">
                  <div className="text-box">
                  
                  <h4 className="investdesh4">{props.investdesh4}</h4>
                  <p className="inevestappdes">{props.inevestappdes}</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
      
            
          
      </div>
        
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentApproach;
