import React from 'react'
import videobg from "../../Assets/videos/banner-bg.mp4";
import "./index.css"
import { useNavigate } from 'react-router-dom';

const Thankew = () => {
    let navigate = useNavigate();
    let handleClick = (val) => {
      navigate(val);
      window.scrollTo(0, 0);}
  return (
    <div>
           <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
             
              <h1 className="headingline2">
      THANK YOU
               
            
              </h1>
              <p>AVIGNA - WE ARE HERE FOR YOU. LET'S CONNECT!</p>
            
            </div>
          </div>
        </div>
      </div>
         <div className="container-fluid content-section px-0 ">
        {/* <div className="contactrow ">
          <div className="col-sm-12 ">
            <div className="innercol">
              <div className="contact-title">
                <h2>
                  We’d love to hear from you.<br></br> Let’s talk about it!
                </h2>
              </div>
            </div>
          </div>
        </div> */}

        <div className="contactrow  ">
          <div className="col-sm-12 formcon2 ">
            <div className="thnkewpading">
            <div className="contact-title">
                <h2>
                Thank YOU
                </h2>
              </div>
                
            <div className="sucess">
                  Thanks for contacting us!<br></br> We will be in touch with you shortly
                </div>
                <button   onClick={() => {
                    handleClick("/");
                  }}  type="button" className="btn fs-6 THNKEWNUTTON">BACK TO HOME</button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thankew