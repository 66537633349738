import Milkvilla from "../../Assets/images/MilkVilla.jpg";
import Allcanfarm from "../../Assets/images/allcanfarm.jpg";
import ourinvvest3img from "../../Assets/images/ourinvest3img.jpg";
import JankiAgro from "../../Assets/images/Janki-Agro-Pvt.Ltd-logo.png";
import InfraGuard from "../../Assets/images/InfraGuard-Logo.png";
import AllCanFarm from "../../Assets/images/AllCanFarm-Logo.png";
import PurpleTulsi from "../../Assets/images/Purple-Tulsi-Logo.png";
import Kumolus from "../../Assets/images/Kumolus-Logo.png";
import Maati from "../../Assets/images/Dhurmati-Logo.png";
import ShwetaMishra from "../../Assets/images/SHWETA-MISHRA.jpg";
import JohnDuBois from "../../Assets/images/JOHN-DU-BOIS-1-3.jpeg";
import PrashantGyan from "../../Assets/images/PRASHANT-GYAN-1.jpg";
import PriyaMishra from "../../Assets/images/PRIYA-MISHRA.jpg";
import MadhavRaj from "../../Assets/images/MADHAV-RAJ-1.jpg";
import PREPARATION from "../../Assets/images/processicon1.jpg";
import PROTOTYPE from "../../Assets/images/processicon2.jpg";
import EXECUTION from "../../Assets/images/processicon3.jpg";
import LAUNCHED from "../../Assets/images/processicon4.jpg";

let data = {
  ourvalues: [
    {
      name: "PASSION",
      valno: "01",
      des: "Finding organization passion is a long journey. Avigna found its passion in designing programs to solve skill gaps in industries and as an organization we nurture this journey every day.",
    },
    {
      name: "PREACH",
      valno: "02",
      des: "All our preaching comes from our first-hand experience of setting up teams in our previous roles. Avigna never shied away from taking the right path through out its journey.",
    },
    {
      name: "CARING",
      valno: "03",
      des: "There are many ways of helping others and Avigna is happy to share all the courses for public utilization at public platforms. Sharing and caring impels us to grow as an organization.",
    },
    {
      name: "FUN",
      valno: "04",
      des: "At Avigna, we do our daily work and processes with fun and excitement and it adds element of fun to our daily work and doesn’t make it mundane.",
    },
    {
      name: "ETHICAL",
      valno: "05",
      des: "Ethics is one of the pillars of Avigna. We believe in ethics and ethical behaviour, which makes us stand out of crowd. It’s the key to all our processes.",
    },
    {
      name: "Humble",
      valno: "06",
      des: "Being humble help us to stay grounded and well connected to the people and keeps us moving towards our goal. This helps Avigna to see themselves accurately and appreciate feedback.",
    },
  ],
  ourInvestments: [
    {
      name: "Milk Villa",
      img: Milkvilla,
      btn: "Learn more",
    },
    {
      name: "PREACH",
      img: Allcanfarm,
      btn: " Learn more",
    },
    {
      name: "CARING",

      img: ourinvvest3img,
      btn: " Learn more",
    },
  ],
  ourportfolio: [
    {
      name: "Janki Agro",
      img: JankiAgro,
    },
    {
      name: "InfraGuard",
      img: InfraGuard,
    },
    {
      name: "AllCanFarm",

      img: AllCanFarm,
    },
    {
      name: "Purple Tulsi",

      img: PurpleTulsi,
    },
    {
      name: "Kumolus",

      img: Kumolus,
    },
    {
      name: "Maati",

      img: Maati,
    },
  ],
  investsectors: [
    {
      name: "IT and Software ",
      valno: "01",
      des: "IT and Technology sector holds huge investment opportunity. In this technologically assisted world, corporates and companies are looking for investing in this sector. The sector itself holds a huge potential for growth and returns.",
    },
    {
      name: "Public Cloud ",
      valno: "02",
      des: "Public Cloud is an on-demand computing services and infrastructure service managed by the provider and can be used by multiple organization over public internet. Expansion in this sector is rapid and it is seeing an investment trend.",
    },
    {
      name: "Education ",
      valno: "03",
      des: "Education sector is backbone of any economy. Investment in this sector proofs to be a strong asset for the economy and society. Education has multiple facets in the society. Investment in this sector helps in betterment of livelihood.",
    },
    {
      name: "Agriculture and Dairy",
      valno: "04",
      des: "Investmen in agriculture and diary ensures better livelihood and spurring economic growth. Investment in new agricultural techniques provides farmers an end-to-end solution to boost and stabilize their income",
    },
  ],
  teammember: [
    {
      name: "Shweta Mishra",
      img: ShwetaMishra,
      designation: "CEO",
    },
    {
      name: "John Du Bois",
      img: JohnDuBois,
      designation: "CHAIRMAN",
    },
    {
      name: "Prashant Gyan",

      img: PrashantGyan,
      designation: "STRATEGIC ADVISOR",
    },
    {
      name: "Priya Mishra",

      img: PriyaMishra,
      designation: "LEADING NEW INITIATIVE",
    },
    {
      name: "Madhav Raj",

      img: MadhavRaj,
      designation: "LEGAL ADVISOR",
    },
  ],
  processbox: [
    {
      name: "PREPARATION",
      img:PREPARATION,
      no:"01",
    },
    {
      name: "PROTOTYPE",
      img: PROTOTYPE,
      no:"02",
    },
    {
      name: "EXECUTION",

      img: EXECUTION,
      no:"03",
    },
    {
      name: "LAUNCHED",

      img:LAUNCHED,
      no:"04",
    },

  ],
};

export default data;
