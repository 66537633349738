import React from "react";
import "./index.css";
import data from "../../Assets/Data";
import { Link, useNavigate } from "react-router-dom";

const Ourportfolio = () => {

  let navigate = useNavigate();
  let handleClick = (val) => {
    navigate(val);
    window.scrollTo(0, 0);}
  return (
    <div>
      <div className="container-fluid ourportfoliobg ">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="ourportfoliotitle" >
                <h6>
                  DISCOVER WHAT LIGHTS YOU UP AND THEM PURSUE IT PASSIONATELY
                </h6>
                <h2>Our Portfolio</h2>
              </div>
              <div className='text-box'><h4>EVERY COMPANY WE HAVE INVESTED IN HAVE A DISTINCTIVE STORY TO TELL, EITHER THEY ARE FROM IT, CLOUD, AGRICULTURE, OR FASHION.</h4>
                  <div class="custom-link">
                  <Link to={"/portfolio"} title="Getstarted" onClick={() => {
                    handleClick("/portfolio");
                  }}>
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>Know more</b>
                  </Link>
                </div>
                </div>
            </div>
            <div className="col-md-7">

                <div className="row">
                    <div className="emptydiv"></div>
                {data.ourportfolio.map((val, id) => {
  return (
                    <div className="col-4">
                        <div className="client-box">
                            <img  className="portfolioimges"src={val.img}></img>
                            <small>{val.name}</small>
                        </div>
                    </div>
  );
                 } )}
              
                </div>

               
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourportfolio;
