import React from "react";
import "./index.css";
import Avignaimg from "../../Assets/images/Avigna-logo.svg";

const Footer = () => {
  return (
    <div>
      <div className="container-fluid  footerbg">
        <div className="container">
          <div className="row footerrow1">
            <div className="col-lg-8  p-0 ">
              <div className="innercol">
              <div className="footerimg">
                <img className="footerquoteimg" src={Avignaimg} />
              </div>
              <h2 className="footerheading">
                
                We are interesting people to know and work with. Lets
                unite for a greater cause!
              </h2>
              </div>
            </div>
          </div>
        
          <div className="row footerow2  ">
            <div className="col-lg-4">
              <div className="widget">
                <p>Phone</p>
                <p><a  className="anchorfooter" href="tel:+65 8363 8464">+65 8363 8464</a></p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" widget">
                <p>Email</p>
                <p>
                  <a href="mailto:info@avigna.biz" className="anchorfooter" >
                   <span > info@avigna.biz</span>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" widget">
                <p>Address</p>
                <p>#18-03,1 North Bridge Road,Singapore –179094</p>
              </div>
            </div>
          </div>
        
          <div className="row">
            <div className="col-lg-8">
              <h5 className="CONNECTUS">CONNECT WITH US</h5>
              <ul className="socialinkul">
                <li >
                  <a className="socialink" href="https://www.facebook.com/avignabiz/">
                    FACEBOOK
                  </a>
                </li>
                <li>
                  <a className="socialink" href="https://www.linkedin.com/company/avigna/">
                    LINKEDIN
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
            <p class="copyright">© 2023 Avigna Consulting PTE. LTD. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
