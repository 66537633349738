import React from 'react'
import "./index.css"
import data from '../../Assets/Data'

const Teammember = () => {
  return (
    <div>
         <div className='container-fluid knowmoresection'>
            <div className='container'>
              <div className='knwmoresectitle'>
                <h6>OUR AMAZING FULLY SKILLED TEAM MEMBERS</h6>
                <h2 data-text="Team">Team Members</h2>
              </div>
              <div className='row'>
              {data.teammember .map((val, id) => {
                  return (
                
                <div className='col-lg-2  team-membercol'>
                  <div className='team-member'><img  className="team-memberimg" src={val.img}/>
                  <small>{val. designation}</small>
                  <h4>{val. name}</h4>
                  </div>
                
                </div>
                 );
                })}
              
              </div>

            </div>
        </div>
    </div>
  )
}

export default Teammember