import React from "react";
import "./index.css";
import videobg from "../../Assets/videos/banner-bg.mp4";
import allcanfarmbannerimg from "../../Assets/images/AllCanFarmbannerimg.jpg";
import allcanfarm1stimg from "../../Assets/images/allcanfarm1stimg.jpg";
import allcanfarm2ndimg from "../../Assets/images/allcanfarm2ndimg.jpg";
import Preloader from "../../component/Preloader";

const Allcanfarm = () => {
  return (
    <>
    {/* <Preloader/> */}
    <div>
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href=   "https://www.facebook.com/avignabiz/"   target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li> 
              <li>
                <a href="https://www.linkedin.com/company/avigna/" target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">Allcan Farm</h1>
              <p>FARMING SOLUTIONS FOR FARMERS</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container  ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA – START FARMING WITH US</h6>
                <h2>
                  <strong>AllCanFarm</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>Is an ambitious initiative to make farmers a professional partner</h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  <strong>AllCanFarm</strong> is an ambitious initiative to make
                  farmers a professional partner. This way Avigna will be able
                  to reduce the risk of the farmers. It helps the farmers with
                  farming strategies, in growing their business while securing
                  their futures. Professionals will be able to add farming into
                  their investment portfolio and also get an opportunity to live
                  farmers life without making much change in their regular work
                  life.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p>Providing farming solutions</p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
              <div className="innercol">
                <div className="row m-0 ">
                  <div className="col-sm-12 p-0 ">
                    <p className="px-0">
                      <img
                        className="milkbannerimg"
                        src={allcanfarmbannerimg}
                      ></img>
                    </p>

                    <div className="row mx-0">
                      <div className="col-sm-6 colmilk ps-0">
                        <p className="px-0">
                          <img
                            className="milkvillaimges"
                            src={allcanfarm1stimg}
                          />
                        </p>
                      </div>

                      <div className="col-sm-6  colmilk pe-0">
                        <p className="">
                          <img
                            className="milkvillaimges"
                            src={allcanfarm2ndimg}
                          />
                        </p>
                      </div>
                    </div>

                    <p className=" milkdes p-0">
                      On the <strong>AllCanFarm</strong> platform, we embrace
                      the profound importance of the farmer’s role in
                      cultivating the food we savour. We have gathered a network
                      of dedicated farmers, each with their own patch of fertile
                      soil, tending to their crops with unwavering passion.
                      Through our platform, these farmers diligently grow and
                      nurture the crops destined to become part of your table
                      and your sustenance. AllCanFarm is a wholly owned
                      subsidiary of Avigna, which encourages angel farmers to
                      adopt different plantations, such as Mustard, Pulses, and
                      flaxseeds, apart from other plants, which will reduce
                      carbon footprints in the long run. The plantation will not
                      only lead to farm produce but will also produce
                      by-products that are “carbon neutral”. Carbon neutrality
                      can be traded against “Maati Coins”. The aim is to make
                      pure produce available to the masses under the name
                      “Maati”. Avigna is also aiming to plant 20,000 different
                      trees by Q4 2022, which will contribute to reducing its
                      carbon footprint. These plantations are about ini seeding,
                      which will be carried out by Avigna, and then we will
                      start onboarding farmers who are aligned with this idea.
                      We hope to bring change to the unorganised farming sector
                      with this investment
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Allcanfarm;
