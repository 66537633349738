import React from 'react'
import "./index.css"
import data from '../../Assets/Data'

const Investsectors = () => {
  return (
    <div>
   <div className="container-fluid ourvaluescon " >
        <div className="container">
            <div className="valtitle .light"> 
          <h6 className="ourvalueshead">AVIGNA - AN ASSET TO SECTORS</h6>
          <h2>Investment in Sectors</h2></div>
          <div className="row">
            {data.investsectors.map((val, id) => {
              return (
                <div className="col-sm-3">
                    <div className=" servicesbox d-flex">
                    <div className="leftdiv">
                        <small>{val.valno}</small>
                        <span></span>

                    </div>
                   

                <div className="rightdiv">  <h4><b>{val.name}</b></h4></div></div>
                  <div>
                  <p className="ourdes"><span>{val.des}</span></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Investsectors