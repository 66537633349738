import React from "react";
import "./index.css";

const Conquerinvest = (props) => {
  return (
    <div>
      <div>
        <div className="Container-fluid investmentcon">
          <div className="container ">
            <div className="invlesappttittal">
              <h6 className="investheading">{props.heading}</h6>
              <h2 data-text="Avigna" className="investappsubheading">
                {props.subhaeding}
              </h2>
            </div>
            <h4 className="abouth4">
              TO CREATE A POWERFUL PROJECT ONCE, A BIT OF LUCK IS ENOUGH.
            </h4>
            <div className="text-box ">
              <p>
                Avigna have started its investment journey in sectors like
                Technology, Agritech, Crypto and Social Welfare. Avigna took the
                challenge of bringing standardization to the rural sectors and
                growing sectors in India and started investing towards it. It is
                bringing technology, ecosystem, carbon neutral benefits together
                in one platform and helping in building a critical
                infrastructure for the society.
              </p>
              <p>
                In technology sector, we have incorporated our cloud management
                knowledge with the growing cloud ecosystem and our product is a
                giant step towards addressing the gap in the skillset in the
                cloud ecosystem. Cloud technology is one of the highly
                flourishing and a demanding sector with enormous growth. Like
                our investment projects <strong>Infraguard</strong>, which
                manages and works towards automation of server operations.
                <strong>The Course Guru</strong> bridges the skill gap within
                the cloud team of corporates. <strong>Kumolus</strong> works
                toward simplification of cloud system, its management,
                automation, governance and cost effectiveness.
              </p>
              <p >
                In Agritech, Avigna is working toward addressing several
                challenging fronts like productivity, efficiency, and
                infrastructure in the farming sector. Avigna is encouraging the
                farmers to be the professional partners and be in this journey
                of great work. Voluntarily farmers (Angel Farmers) can get
                associated with us or on the basis of corporate incentives,
                e.g., 5 Mango trees are given by corporates for planting; which
                in turn generates carbon neutral benefits by reducing carbon
                dioxide in the environment. Avigna is bringing technology and
                environment on one platform. Our project
                <strong>AllCanFarm</strong> is a mobile based application where
                farmers can list their farm produce for adoption and this will
                not make much changes in their regular work life. Avigna is also
                aiming at planting 20,000 different trees by Q4 2022 and it will
                contribute in reducing carbon footprint.
              </p>
              <p >
                Avigna is ingressing into the <strong>Crypto market</strong> and
                working on the mission to accelerate the ongoing transition to
                cryptocurrency. Crypto is comparatively challenging due to its
                mode of operations and it includes digital payment without the
                involvement of banking institute. It’s a technology supported
                arena and lot of research and work is being carried on. Avigna
                is focusing on trading of Carbon Neutral by introducing the
                <strong>“Maati Coins”</strong>. This in turn will create
                awareness amongst the farmers and agriculturalist.
              </p>
              <p >
                In Social Welfare sector, Avigna invested in ecommerce platform
                and in modernising dairy industry. Technology has played an
                important role in boosting the ecommerce sector with options
                like order status, tracking shipment and with secure online
                payment options. This enables a large population of customer to
                access the ecommerce site anytime, anywhere with an ease. Avigna
                invested in ecommerce venture <strong>Purple Tulsi</strong>,
                which is one-stop solution for online fashion and home needs. On
                the other hand, <strong>Janki Agro</strong> focuses on bringing
                structure to unorganized dairy industry. It’s a social welfare
                enterprise where milk is collected from dairy farmers and kept
                in refrigerated storage plants for conducting lab test while
                maintaining its pure and natural state. There is a long term
                plan of building multiple milk hubs with storage capacity of
                100K liters of milk per day. Similarly,
                <strong>Milkvilla</strong> provides direct access to primary
                producers and ensures doorstep milk collection and delivery in
                non-packaged form. Utilizing state-of-the-art technology,
                including IoT-enabled refrigerated vehicles, the entire milk
                supply chain has been digitalized, guaranteeing maximum
                freshness and quality while eliminating any human touch during
                the process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conquerinvest;
