import React from "react";
import videobg from "../../Assets/videos/banner-bg.mp4";
import dhurmaatibanner from "../../Assets/images/dhurmaatibanner.jpg";
import dhurmaatiistimg from "../../Assets/images/dhurmatiistimg.jpg";
import dhurmaati2ndimg from "../../Assets/images/dhurmaati2ndimg.jpg";
import Preloader from "../../component/Preloader";

const Dhurmaati = () => {
  return (
    
  
    <>
    {/* <Preloader/> */}
    <div>
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">Dhur Maati</h1>
              <p>ORGANIC PRODUCE DIRECT TO YOUR HOME</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA–SUPPORTING FARMERS AND CARBON NEUTRALS</h6>
                <h2>
                  <strong>Dhur Maati</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>
                  FROM FARMING TO CO-OWNING PLANTATION TO CARBON NEUTRALS.
                </h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  Maati is a marketplace for all the produce from AllCanFarm and
                  it’s a wholly owned subsidiary of Avigna. It is a vertical of
                  AllCanFarm, which allows to angel farmer to list the crops and
                  fruits produced on the adopted farm to reach urban homes. The
                  concept of Maati is straightforward: deliver pure and
                  unprocessed food directly to consumers’ tables. This model
                  works best as it bypasses intermediaries like wholesalers,
                  distributors, and retailers. Maati allows to earn reasonable
                  profit without splitting the same with middlemen. In this
                  portfolio, Avigna connects with farmers to manage the planning
                  and infrastructure required for different produce.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p style={{textAlign: "left"}}>
                        Planned natural and traditional fruits and vegetables
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
              <div className="innercol">
                <div className="row m-0 ">
                  <div className="col-sm-12 p-0 ">
                    <p className="px-0">
                      <img
                        className="milkbannerimg"
                        src={dhurmaatibanner}
                      ></img>
                    </p>

                    <div className="row mx-0">
                      <div className="col-sm-6  colmilk ps-0">
                        <p className="px-0">
                          <img
                            className="milkvillaimges"
                            src={dhurmaatiistimg}
                          />
                        </p>
                      </div>

                      <div className="col-sm-6 colmilk pe-0">
                        <p className="">
                          <img
                            className="milkvillaimges"
                            src={dhurmaati2ndimg}
                          />
                        </p>
                      </div>
                    </div>

                    <p className=" milkdes p-0">
                      <strong>Maati</strong> App focuses on farm
                      scalability and meeting urban demands. It is a viable
                      business model for angel farmers who are willing to invest
                      in marketing and distribution and who can establish a
                      strong connection with their local community. It also
                      provides them with an opportunity to support local
                      agriculture and sustainable farming practises. Sustainable
                      farming practises and environmental responsibility become
                      the key selling points for many consumers. The Maati App
                      is indeed a viable and rewarding option for angel farmers
                      who are willing to put in the work to build relationships
                      with their customers and provide high-quality, sustainably
                      produced produce.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Dhurmaati;
