import React from "react";
import "./index.css";

import videobg from "../../Assets/videos/banner-bg.mp4";
import InvestmentApproach from "../../component/Investment/Index";
import Ourvalues from "../../component/ourvalues";
import Ourinvestment from "../../component/ourinvestments";
import Knowmore from "../../component/know-more";
import Ourportfolio from "../../component/ourportfolio";
import btnsvg from "../../Assets/images/btnslider.svg"
import Preloader from "../../component/Preloader";
import { Link, useNavigate } from "react-router-dom";



const Homepage = () => {
  let navigate = useNavigate();
  let handleClick = (val) => {
    navigate(val);
    window.scrollTo(0, 0);}
  return (
    <>
    {/* <Preloader/> */}
    
    
    <div>
      
 
      <div className="container-fluid p-0">
   
        
        <div className="headerbanner">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a
                  href="https://www.facebook.com/avignabiz/"
                  target="_blank"
                  title="FACEBOOK"
                >
                  FACEBOOK
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/avigna/"
                  target="_blank"
                  title="LINKEDIN"
                >
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span className="scrollbox"></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <div class="tagline ">
                <span className="headerline1"></span>
                <h6 className="headingist">
                  SINGAPORE I AUSTRALIA I USA I INDIA
                </h6>
              </div>
              <h1 className="headingline2">
                Innovate
                <br></br>
                <span className="invest">Invest</span>
              </h1>
              
              <div class="slide-btn">
              <Link  onClick={() => {
                    handleClick("/portfolio");
                  }} to="/portfolio">
                  <div class="lines">
                    <span></span> <span></span>
                  </div>
               

                <div><img className="btnimg" src={btnsvg}/></div>
               
                  <b>Know More</b>
                  </Link>
          
              </div>
            </div>
          </div>
          
        </div>
      </div>
      
  
      <InvestmentApproach
        heading="AVIGNA INVESTMENT APPROACH"
        subhaeding="We have loads of fun while doing our work, makes us eager to dig in more and complete them!"
        investdesh4="INNOVATE, INVEST, IMPROVISE AND HAVE FUN."
        inevestappdes="Our investment approach is simple.We find gaps in an ecosystem ,invest in a team that has deep expertise in the area. And together,we create a solution that can fix this gap.Our team doesn’t hesitate to get their hands dirty in solving problems,we are proud of it,and we have loads of fun while doing it! We design our investment strategy in such a way that different sectors objectives, values and their individual skills make different tactics and strategies appropriate. Choices involve a trade-off between and return."
         
      />
      <Ourvalues />
      <Ourinvestment />
      <Knowmore />
      <Ourportfolio />
   
    </div>
    </>
  );
};

export default Homepage;
