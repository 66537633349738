
import React from 'react'

const Button = (props) => {
  return (
    <div>
          <button
     
     className={props.className}
     type={props.type}
     placeholder={props.placeholder}
     id={props.id}
     value={props.value}
     name={props.name}
     style={{
       width: props.width,
       height: props.height,
       padding: props.padding,
       border: props.border,
       backgroundColor: props.bg,
       borderRadius: props.borderRadius,
     }}
     onChange={props.onChange}
     maxLength={props.maxlength}
   >{ props.children}</button>
      
    </div>
  )
}

export default Button;