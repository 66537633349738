import React from 'react'
import "./index.css"

import { Link, useNavigate } from "react-router-dom";

const Knowmore = () => {
  
  let navigate = useNavigate();
  let handleClick = (val) => {
    navigate(val);
    window.scrollTo(0, 0);}
  
  return (
    <div>
        <div className='container-fluid knowmoresection '>
            <div className='container '>
              <div className='innercol'>
              <div className='knwmoresectitle'>
                <h6>KNOW MORE ABOUT OUR PORTTOLIO</h6>
                <h2 data-text="Avigna">So that's us. There's no other way to put it.</h2>
              </div>
              <div className='row'>
                <div className='col-sm-5'>
                  <div className='text-box'><h4>Avigna believes in leveraging human expertise and financial capital for success in the investment sectors.</h4>
                  <div class="custom-link">
                  <Link  onClick={() => {
                    handleClick("/portfolio");
                  }} to="/portfolio">
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>Getstarted</b>
                  </Link>
                </div>
                </div>
                </div>
                <div className='col-sm-7'></div>
                <div></div>
              </div>
              </div>

            </div>
        </div>
    </div>
  )
}

export default Knowmore