import React, { useEffect, useState, useMemo } from "react";
import "./index.css";
import Input from "../input";
import Select from "react-select";
import countryList from "react-select-country-list";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useForm } from "@formcarry/react";

const ContactForm = () => {
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setState({ ...state, country: value });
    // setValue(value);
  };
  let [show, setShow] = useState(false);
  let [states, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    country: "",
    contact: "",
    company: "",
    message: "",
  });
  let inputhandler = (w) => {
    let { name, value } = w.target;
    setState({ ...states, [name]: value });
  };
  let initialState = {
    fname: "",
    lname: "",
    email: "",
    country: "",
    contact: "",
    company: "",
    message: "",
  };
  let [error, setError] = useState(initialState);

  const addContactFormValidation = () => {
    console.log(states);
    if (
      states.fname === "" ||
      states.lname === "" ||
      states.email === "" ||
      states.company === "" ||
      states.country === "" ||
      states.message === "" ||
      states.contact === ""
    ) {
      setError({
        ...error,
        fname: states.fname !== "" ? "" : "Please Enter First name",
        lname: states.lname !== "" ? "" : "Please Enter Last name",
        email: states.email !== "" ? "" : "Please Enter email",
        message: states.message !== "" ? "" : "Please Enter Message",
        country: states.country !== "" ? "" : "Please Enter Country",

        company:
          states.company == ""
            ? "Please Enter your Company"
            : states.company.length < 5
            ? "Please Enter valid  Company"
            : "",
        contact:
          states.contact == ""
            ? "Please Enter your Number"
            : states.contact.length < 10
            ? "Please Enter valid  Number"
            : "",
        // company:
        //   state.company !== ""
        //     ? ""
        //     : "Please Enter Company",
        // contact:
        //    state.contact == ""
        //     ? state.contact.length > 10
        //       ? "Please enter valid number"
        //       : ""
        //     : "Please Enter Contact number",
      });
      return false;
    } else {
      return true;
    }
  };
  let navigate = useNavigate();
  const { state, submit } = useForm({
    id: 'Gx43EcgJe0',
  })
  
  if (state.submitting) {
  
 
    navigate('/thank-you')
  }

  const [inputstate, setInputstate] = useState({
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    phone_no: "",
    message: "",
  });

  // let handleSelected = (data) => {
  //   let Selectedvalue = data.map((val) => {
  //     return val.value
  //   })
  //   SetSelected(data)
  // }

  // const inputhandler = (e) => {
  //   const { name, value } = e.target
  //   setInputstate({ ...inputstate, [name]: value })
  // }
  return (
    <div>
      <div className="container-fluid content-section px-0 ">
        <div className="contactrow ">
          <div className="col-sm-12 ">
            <div className="innercol">
              <div className="contact-title">
                <h2>
                  We’d love to hear from you.<br></br> Let’s talk about it!
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="contactrow  pt-5">
          <div className="col-sm-12 formcon ">
            <div className="innercol">
              {/* ist form */}
              {/* {!show ? ( */}
              <form
                onSubmit={submit}
                style={{ display: "block" }}
                className="form1"
              >
                <div className="row form-row">
                  <div className="col-sm-6">
                    <label
                      className="contactlabel"
                      style={
                        error.fname === ""
                          ? { color: "#494d50" }
                          : { color: "#fa5252" }
                      }
                      for=""
                    >
                      First Name<span className="astrick"> *</span>
                    </label>
                    <input
                      width="100%"
                      id="fname"
                      name="fname"
                      required
                      onChange={inputhandler}
                      value={states.fname}
                      style={
                        error.lname === ""
                          ? { border: "1px solid #8e98a2" }
                          : { border: "1px solid #fa5252" }
                      }
                    />
                    <p className="errorp">{error?.fname}</p>
                  </div>
                  <div className="col-sm-6">
                    <label
                      className="contactlabel"
                      style={
                        error.lname === ""
                          ? { color: "#494d50" }
                          : { color: "#fa5252" }
                      }
                      for=""
                    >
                      Last Name<span className="astrick"> *</span>
                    </label>
                    <input
                    required
                      width="100%"
                      id="lname"
                      onChange={inputhandler}
                      name="lname"
                      value={states.lname}
                  
                      style={
                        error.lname === ""
                          ? { border: "1px solid #8e98a2" }
                          : { border: "1px solid #fa5252" }
                      }
                    />
                    <p className="errorp">{error?.lname}</p>
                  </div>
                </div>
                <div className="form-row">
                  <label
                    className="contactlabel"
                    style={
                      error.email === ""
                        ? { color: "#494d50" }
                        : { color: "#fa5252" }
                    }
                    for=""
                  >
                    Email<span className="astrick"> *</span>
                  </label>
                  <input
                    width="100%"
                    required
                    id="email"
                    name="email"
                  
                    onChange={inputhandler}
                    value={states.email}
                    style={
                      error.lname === ""
                        ? { border: "1px solid #8e98a2" }
                        : { border: "1px solid #fa5252" }
                    }
                  />
                  <p className="errorp">{error?.email}</p>
                </div>
                <div className=" form-row">
                  <label
                    className="contactlabel"
                    style={
                      error.country === ""
                        ? { color: "#494d50" }
                        : { color: "#fa5252" }
                    }
                    for=""
                  >
                    Country<span className="astrick"> *</span>
                  </label>
                  <Select
                    id="country"
                    name="country"
                    required
                    // className="dropdown1"
                    options={options}
                    value={states.country}
                    onChange={changeHandler}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,

                        boxShadow: states.isFocused ? 0 : 0,
                        // '&:hover': { borderColor: 'gray' }, // border style on hover
                        // border: '1px solid lightgray', // default border color
                        // boxShadow: 'none', // no box-shadow
                        borderRadius: 0,
                        borderWidth: 0.1,
                        borderColor: states.isFocused ? " #0095ff" : "#8e98a2",
                      }),
                    }}
                    placeholder="Select Country"
                  />
                </div>
                {/* <div className=" form-row">
                    <label
                      className="contactlabel"
                      style={
                        error.country === ""
                          ? { color: "#494d50" }
                          : { color: "#fa5252" }
                      }
                      for=""
                    >
                      Country<span className="astrick"> *</span>
                    </label>
                    <select
                      id="country"
                      name="country"
                      // required="required"
                      onChange={inputhandler}
                      value={state.country}
                      className="dropdown1"
                    >
                      <option
                        value=""
                        className="dropdown2"
                        disabled
                        selected="selected"
                      >
                        Select Country
                      </option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                    </select>
                    <p className="errorp">{error.country}</p>
                  </div> */}
                <div className=" form-row">
                  <label
                    className="contactlabel"
                    style={
                      error.contact === ""
                        ? { color: "#494d50" }
                        : { color: "#fa5252" }
                    }
                    for=""
                  >
                    Contact Number<span className="astrick"> *</span>
                  </label>
                  <input
                    width="100%"
                    required
                    id="contact"
                    name="contact"
                    // required="required"
                    onChange={inputhandler}
                    value={states.contact}
                    style={
                      error.lname === ""
                        ? { border: "1px solid #8e98a2" }
                        : { border: "1px solid #fa5252" }
                    }
                  />
                  <p className="errorp">{error.contact}</p>
                </div>
                <div className=" form-row">
                  <label
                    className="contactlabel"
                    style={
                      error.company === ""
                        ? { color: "#494d50" }
                        : { color: "#fa5252" }
                    }
                    for=""
                  >
                    Company<span className="astrick"> *</span>
                  </label>
                  <input
                  required
                    width="100%"
                    id="company"
                    onChange={inputhandler}
                    name="company"
                    // required="required"
                    value={states.company}
                    style={
                      error.lname === ""
                        ? { border: "1px solid #8e98a2" }
                        : { border: "1px solid #fa5252" }
                    }
                  />
                  <p className="errorp">{error.company}</p>
                </div>
                <div className=" form-row">
                  <label
                    className="contactlabel"
                    style={
                      error.message === ""
                        ? { color: "#494d50" }
                        : { color: "#fa5252" }
                    }
                    for=""
                  >
                    Message<span className="astrick"> *</span>
                  </label>
                  <textarea
                    width="100%"
                    className="textarea"
                    name="message"
                    id="message"
                    onChange={inputhandler}
                    value={states.message}
                    style={
                      error.lname === ""
                        ? { border: "1px solid #8e98a2" }
                        : { border: "1px solid #fa5252" }
                    }
                  />
                  <p className="errorp">{error.message}</p>
                </div>
                <div className="form-row">
                  <Button
                    className="contactbutton"
                    type="submit"
                    id="submit-button"
                  >
                    Submit
                  </Button>
                </div>
              </form>
              {/* // ) : ( */}
              {/* <form style={{ display: "none" }} className="form2"> */}
              {/* 
              //   <div className="sucess">
              //     Thanks for contacting us! We will be in touch with you shortly{" "}
              //   </div> */}
              {/* // )} */}
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
