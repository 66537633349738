import React from "react";
import videobg from "../../Assets/videos/banner-bg.mp4";
import kumolousbannerimg from "../../Assets/images/Cloud-boltBig-banner.jpg";
import kumolous1stimg from "../../Assets/images/kumolous1stimg.jpg";
import kumolous2ndimg from "../../Assets/images/kumolus2ndimg.jpg";
import Preloader from "../../component/Preloader";
import Infraguard from "../infraguard";

const Kumolous = () => {
  return (
    <>
    {/* <Preloader/> */}
    <div>
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.facebook.com/avignabiz/" target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">Kumolus</h1>
              <p>SIMPLIFIED CLOUD SERVICES</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container  ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA – HAVE FAITH IN US FOR CLOUD COMPUTING</h6>
                <h2>
                  <strong>Kumolus</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>EMPOWERING ENTERPRISES WITH SAAS-BASED CLOUD SOLUTIONS.</h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  In the rapidly evolving landscape of cloud computing,
                  enterprises face numerous challenges when managing their
                  infrastructure on platforms like Microsoft Azure and Amazon
                  Web Services (AWS). These challenges encompass visualization,
                  cost optimization, and governance of their cloud environments.
                  Fortunately, Kumolus, a leading SaaS-based solution provider,
                  has emerged to address these issues, empowering enterprises to
                  make the most of their cloud investments. Avigna believed in
                  Kumolus and invested for their ability to cater the SAAS based
                  needs of enterprises.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p style={{textAlign: "left"}}>
                        Simplification of cloud computing
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2019</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
              <div className="innercol">
                <div className="row m-0 ">
                  <div className="col-sm-12 p-0 ">
                    <p className="px-0">
                      <img
                        className="milkbannerimg"
                        src={kumolousbannerimg}
                      ></img>
                    </p>

                    <div className="row mx-0">
                      <div className="col-sm-6 colmilk ps-0">
                        <p className="px-0">
                          <img
                            className="milkvillaimges"
                            src={kumolous1stimg}
                          />
                        </p>
                      </div>

                      <div className="col-sm-6 colmilk pe-0">
                        <p className="">
                          <img
                            className="milkvillaimges"
                            src={kumolous2ndimg}
                          />
                        </p>
                      </div>
                    </div>

                    <p className=" milkdes p-0">
                      <strong>Kumolous </strong>works toward the simplification
                      of cloud systems, their management, automation,
                      governance, and cost-effectiveness. It is built to handle
                      the complexity of the cloud environment and approach it
                      with maximum impact. It provides reusability of
                      multi-cloud products. Kumolus helps our customers to
                      accelerate and automate cloud operations at scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Kumolous;
