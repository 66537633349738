import React from "react";
import "./index.css";
import Milkvilla from "../../Assets/images/MilkVilla.jpg";
import Allcanfarm from "../../Assets/images/allcanfarm.jpg";
import ourinvvest3img from "../../Assets/images/ourinvest3img.jpg";
import { Link, useNavigate } from "react-router-dom";

const Ourinvestment = () => {
  let navigate = useNavigate();
  let handleClick = (val) => {
    navigate(val);
    window.scrollTo(0, 0);}
  
  return (
    <div>
      <div className="Container-fluid ourinvestmentcon">
        <div className="container ">
          <div className="innercol">
          <div className="ourinvesttittal">
            <h2 data-text="Works"> Our Investments</h2>
          </div>

          <div className="row ALLROW">
            <div className="col-6    content-box">
              <div className="inner">
              
                <h3 onClick={() => {
                    handleClick("/portfolio/milk-villa");
                  }} >
                  
                  <span className="milkvilatext">     Milk Villa</span>
                </h3>

                <div class="custom-link">
                <a onClick={() => {
                    handleClick("/portfolio/milk-villa");
                  }}>
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 p-0  imgbox ">
              <div className="milkvilabox">
              <a onClick={() => {
                    handleClick("/portfolio/milk-villa");
                  }}>
                <img className="milvilaimg" src={Milkvilla} /></a>
              </div>
            </div>
          </div>
          {/* 2nd row stART */}
          <div className="row ALLROW Allrow2">
            <div className="col-6 p-0 imgbox ">
              <div className="milkvilabox">
              <a onClick={() => {
                    handleClick("/portfolio/allcan-farm");
                  }}>
                <img className="milvilaimg" src={Allcanfarm} /></a>
              </div>
            </div>
            <div className="col-6  content-box">
              <div className="inner">
                <h3 onClick={() => {
                    handleClick("/portfolio/allcan-farm");
                  }}  >
                  <span>Allcan Farm</span>
                </h3>
                <div class="custom-link">
                <a onClick={() => {
                    handleClick("/portfolio/allcan-farm");
                  }} to="/portfolio/allcan-farm">
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* 3RD ROW START */}
          <div className="row ALLROW">
            <div className="col-6  content-box">
              <div className="inner">
                <h3 onClick={() => {
                    handleClick("/portfolio/infraguard");
                  }}>
                  <span>Infra Guard</span>
                </h3>
                <div class="custom-link">
                <a onClick={() => {
                    handleClick("/portfolio/infraguard");
                  }} >
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 p-0 imgbox">
              <div className="milkvilabox"> 
              <a  onClick={() => {
                    handleClick("/portfolio/infraguard");
                  }}to="/portfolio/infraguard">
                <img className="milvilaimg" src={ourinvvest3img} /></a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourinvestment;
