import React from 'react';
import "./index.css";
import Milkvilla from "../../Assets/images/MilkVilla.jpg";
import Allcanfarm from "../../Assets/images/allcanfarm.jpg";
import ourinvvest3img from "../../Assets/images/ourinvest3img.jpg";
import kumolus from "../../Assets/images/cloudboltportfolio.jpg";
import PurpleTulsi from "../../Assets/images/purpletulsiportfolio.jpg";
import JankiAgro from "../../Assets/images/Janki-mainportfolio.jpg";
import Dhurmatiport from "../../Assets/images/Avigna-Dhur-Maati-portfolio.jpg";
import { Link, useNavigate } from 'react-router-dom';

const Ourinvestport1 = () => {
  let navigate = useNavigate();
  let handleClick = (val) => {
    navigate(val);
    window.scrollTo(0, 0);}
  return (
    <div>
             <div className="Container-fluid ourinvestmentcon">
        <div className="container">
          <div className="ourinvestporttittal">
          <h6>AVIGNA – HELPS YOU WITH INVESTMENT RESPONSIBILITIES</h6>
            <h2 data-text="Works">Our investment portfolios and journey so far</h2>
          </div>
          

          <div className="row  m-0 ALLROW  Allrow2">
          <div className="col-6 p-0  imgbox ">
              <div className="milkvilabox">
              <a  onClick={() => {
                    handleClick("/portfolio/milk-villa");
                  }} >
                <img className="milvilaimg" src={Milkvilla} /></a>
              </div>
            </div>
            <div className="col-6 content-box2">
              <div className="inner">
                <h3 onClick={() => {
                    handleClick("/portfolio/milk-villa");
                  }}>
                  <span className="milkvilatext2">Milk Villa</span>
                </h3>

                <div class="custom-link2">
                <a onClick={() => {
                    handleClick("/portfolio/milk-villa");
                  }} >
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
          
          </div>
          {/* 2nd row */}
          <div className="row m-0 ALLROW  ">
            
            <div className="col-6  content-box2">
              <div className="inner innerallcanfarm2">
                <h3 onClick={() => {
                    handleClick("/portfolio/allcan-farm");
                  }}>
                  <span>Allcan Farm</span>
                </h3>
                <div class="custom-link2">
                <a  onClick={() => {
                    handleClick("/portfolio/allcan-farm");
                  }}>
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 p-0  imgbox ">
              <div className="milkvilabox">
              <a  onClick={() => {
                    handleClick("/portfolio/allcan-farm");
                  }} >
                <img className="milvilaimg" src={Allcanfarm} /></a>
              </div>
            </div>
          </div>
          {/* 3rd row */}
          <div className="row m-0 ALLROW  Allrow2">
          <div className="col-6 p-0  imgbox">
              <div className="milkvilabox"> 
              <a onClick={() => {
                    handleClick("/portfolio/infraguard");
                  }} >
                <img className="milvilaimg" src={ourinvvest3img} /></a>
              </div>
            </div>
            <div className="col-6  content-box2">
              <div className="inner">
                <h3  onClick={() => {
                    handleClick("/portfolio/infraguard");
                  }}>
                  <span>Infra Guard</span>
                </h3>
                <div class="custom-link2">
                <a onClick={() => {
                    handleClick("/portfolio/infraguard");
                  }}  >
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
         
          </div>
          {/* 4th row */}
          
          <div className="row m-0 ALLROW">
         
            <div className="col-6 content-box2">
              <div className="inner">
                <h3 onClick={() => {
                    handleClick("/portfolio/kumolous");
                  }}>
                  <span className="milkvilatext2">Kumolus</span>
                </h3>

                <div class="custom-link2">
                <a  onClick={() => {
                    handleClick("/portfolio/kumolous");
                  }} >
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 p-0  imgbox ">
              <div className="milkvilabox">
              <a onClick={() => {
                    handleClick("/portfolio/kumolous");
                  }} >
                <img className="milvilaimg" src={kumolus} /></a>
              </div>
            </div>
          
          </div>
          {/* 5th row */}
          <div className="row  m-0 ALLROW  Allrow2">
          <div className="col-6 p-0  imgbox">
              <div className="milkvilabox"> 
              <a  onClick={() => {
                    handleClick("/portfolio/purple-tulsi");
                  }} >
                <img className="milvilaimg" src={PurpleTulsi} /></a>
              </div>
            </div>
            <div className="col-6  content-box2">
              <div className="inner">
                <h3 onClick={() => {
                    handleClick("/portfolio/purple-tulsi");
                  }}>
                  <span>Purple Tulsi</span>
                </h3>
                <div class="custom-link2">
                <a onClick={() => {
                    handleClick("/portfolio/purple-tulsi");
                  }}>
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
         
          </div>
          {/* 6th row */}
          <div className="row m-0 ALLROW">
            
            <div className="col-6  content-box2">
              <div className="inner innerallcanfarm2">
                <h3 onClick={() => {
                    handleClick("/portfolio/janki-agro");
                  }}>
                  <span>Janki Agro</span>
                </h3>
                <div class="custom-link2">
                <a onClick={() => {
                    handleClick("/portfolio/janki-agro");
                  }} >
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 p-0   imgbox ">
              <div className="milkvilabox">
              <a onClick={() => {
                    handleClick("/portfolio/janki-agro");
                  }}>
                <img className="milvilaimg" src={ JankiAgro } /></a>
              </div>
            </div>
          </div>
        {/* 7th row */}
          <div className="row  m-0 ALLROW  Allrow2">
          <div className="col-6 p-0  imgbox">
              <div className="milkvilabox"> 
              <a  onClick={() => {
                    handleClick("/portfolio/dhur-maati");
                  }} >
                <img className="milvilaimg" src={Dhurmatiport} /></a>
              </div>
            </div>
            <div className="col-6  content-box2">
              <div className="inner">
                <h3 onClick={() => {
                    handleClick("/portfolio/dhur-maati");
                  }}>
                  <span>Dhur Maati</span>
                </h3>
                <div class="custom-link2">
                <a   onClick={() => {
                    handleClick("/portfolio/dhur-maati");
                  }} >
                    <div class="lines">
                      <span></span> <span></span>
                    </div>
                    <b>LEARN MORE</b>
                  </a>
                </div>
              </div>
            </div>
         
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Ourinvestport1;