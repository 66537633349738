import React from "react";
import videobg from "../../Assets/videos/banner-bg.mp4";
import infraguardbannerimg from "../../Assets/images/infraguardbannerimg.jpg";
import infraguard1ndimg from "../../Assets/images/infraguard1stimg.jpg";
import infraguard2ndimg from "../../Assets/images/infraguard2ndimg.jpg";
import Preloader from "../../component/Preloader";

const Infraguard = () => {
  return (
    <>
    {/* <Preloader/> */}
    <div>
      <div className="container-fluid p-0">
        <div className="pageheader">
          <aside className="leftsidetag">
            <ul className="liststylebg">
              <li>
                <a href="https://www.linkedin.com/company/avigna/"  target="_blank" title="FACEBOOK">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="#" target="_blank" title="LINKEDIN">
                  LINKEDIN
                </a>
              </li>
            </ul>
          </aside>
          <div className="scrolldwn1">
            <small className="scroll-down-small">SCROLL DOWN</small>
            <span></span>
          </div>
          <div className=" video-bg">
            <video
              autoPlay
              playsInline
              loop
              muted
              className="videobgname"
              style={{ pointerEvents: "none" }}
            >
              <source src={videobg} />
            </video>
            <div className="container  ">
              <h1 className="headingline2">InfraGuard</h1>
              <p>SOLUTION TO YOUR END-TO-END CLOUD SERVER MANAGEMENT</p>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      <div className="container-fluid allsectiion">
        <div className="container ">
          <div className="row mx-0">
            <div className="col-sm-12 p-0">
              <div className="milkvillatitle">
                <h6>AVIGNA – KNOWS HOW TO GET IT DONE</h6>
                <h2>
                  <strong>InfraGuard</strong>
                </h2>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-5 p-0 ">
              <div className="innercol">
                <h4>Scale your managed service operations. </h4>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="innercol">
                <h5>Description</h5>
                <p>
                  <strong>InfraGuard </strong>is a unified cloud server
                  management, which automates server operations. It includes
                  various stages like Patch Management, Access Management,
                  Automate Server Management, CMDB (configuration management
                  database) and Architected Framework. This product takes a
                  giant step forward towards solving the skills gap found in the
                  cloud ecosystem by reducing the need to highly skilled people
                  to manage cloud workload.
                </p>
              </div>
              <div className="container p-0">
                <div className="row mx-0">
                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Project</h5>
                      <p style={{textAlign: "left"}}>End-to-end cloud server management</p>
                    </div>
                  </div>

                  <div className="col-sm-6 p-0">
                    <div className="innercol">
                      <h5>Launch</h5>
                      <p>2019</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container p-0 ">
              <div className="innercol">
                <div className="row m-0 ">
                  <div className="col-sm-12 p-0 ">
                    <p className="px-0">
                      <img
                        className="milkbannerimg"
                        src={infraguardbannerimg}
                      ></img>
                    </p>

                    <div className="row mx-0">
                      <div className="col-sm-6  colmilk ps-0">
                        <p className="px-0">
                          <img
                            className="milkvillaimges"
                            src={infraguard1ndimg}
                          />
                        </p>
                      </div>

                      <div className="col-sm-6 colmilk pe-0">
                        <p className="">
                          <img
                            className="milkvillaimges"
                            src={infraguard2ndimg}
                          />
                        </p>
                      </div>
                    </div>

                    <p className=" milkdes p-0">
                      <strong>InfraGuard</strong> is designed for managed
                      service providers who manages customers at multi-level
                      with multiple projects and to replicate the account
                      structure. With InfraGuard, organizations can gain full
                      control over the infrastructure from managed services
                      point of view, which has been a blind spot for many.
                      InfraGuard is Avigna’s humble way of sharing years of
                      cloud management knowledge with the world
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Infraguard;
